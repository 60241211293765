import React from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Box, Button, Grid, TextField } from "@material-ui/core";

import { getFirstDayOfTheMonth, getLastDayOfTheMonth } from "../../utils/date";
import HookFormKeyboardDatePicker from "../Inputs/HookFormKeyboardDatePicker";

function ReportForm({ onSubmit }) {
  const { control, handleSubmit } = useForm();

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Box width={1}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <HookFormKeyboardDatePicker
              name="start_date"
              label="Start date"
              variant="dialog"
              inputVariant="outlined"
              defaultDate={getFirstDayOfTheMonth()}
              control={control}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <HookFormKeyboardDatePicker
              name="end_date"
              label="End date"
              variant="dialog"
              inputVariant="outlined"
              defaultDate={getLastDayOfTheMonth()}
              control={control}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Button fullWidth type="submit" variant="contained" color="primary">
              Create report
            </Button>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
}

export default ReportForm;
