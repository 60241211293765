import { connect } from "react-redux";

import { resetPassword } from "../../store/actions/user";

import PasswordResetForm from "./PasswordResetForm";

export default connect(null, (dispatch) => ({
  resetPassword: (oldPassword, newPassword, newPasswordConfirm) =>
    dispatch(resetPassword(oldPassword, newPassword, newPasswordConfirm)),
}))(PasswordResetForm);
