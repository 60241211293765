import { useEffect, useState } from "react";

export default ({ accessToken, refresh, children }) => {
  const [isLoading, setIsLoading] = useState(null);

  useEffect(() => {
    const execute = async () => {
      setIsLoading(true);

      try {
        await refresh();

        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
      }
    };

    if (accessToken && accessToken.length) {
      // We have a access token, try to retrieve user details (it can be that
      // the token is invalid, in that case the reducer will force the user to
      // logout)
      execute();
    } else {
      // Instant set user to unknown status (as we dont have any access token
      // to fetch user details with)
      setIsLoading(false);
    }
  }, [accessToken, refresh]);

  if (isLoading || isLoading === null) {
    return null;
  }

  return children || null;
};
