import React, { useEffect } from "react";

import { Controller } from "react-hook-form";

import { TextField } from "@material-ui/core";

import { convertDecimalToHourString } from "../../../utils/text";

export default ({ name, control, defaultValue = "", ...props }) => {
  let timer;

  const handleOnChange = (event, onChange) => {
    clearTimeout(timer);
    const value = event.target.value;

    onChange(value);

    timer = setTimeout(() => {
      const valueDeLocalized = value.replace(",", ".");
      const parsedFloatValue = parseFloat(valueDeLocalized);
      if (
        valueDeLocalized.length > 2 &&
        valueDeLocalized.includes(".") &&
        !Number.isNaN(parsedFloatValue)
      ) {
        onChange(convertDecimalToHourString(parsedFloatValue));
      }
    }, 500);
  };

  useEffect(() => () => clearTimeout(timer));

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({
        field: { value, onChange, name: fieldName, onBlur, ref },
      }) => (
        <TextField
          name={fieldName}
          value={value || ""}
          inputRef={ref}
          {...props}
          inputProps={{ inputMode: "decimal" }}
          onChange={(event) => handleOnChange(event, onChange)}
          onBlur={onBlur}
        />
      )}
    />
  );
};
