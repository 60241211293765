import React from "react";

import { useTheme } from "@material-ui/core/styles";

import { Bar } from "react-chartjs-2";

import { format } from "date-fns";

import {
  aggregateMinutesFromTimeRegistrations,
  getTimeRegistrationsByDay,
} from "../../utils/entities";

function a11ytext(days, chartValues) {
  if (!chartValues) return null;

  return chartValues
    .map((chartValue, index) => {
      const day = format(days[index], "EEEE");
      return `${day}: ${chartValue} hours.`;
    })
    .join(" ");
}

const options = {
  scales: {
    y: {
      ticks: {
        beginAtZero: true,
      },
      grid: {
        display: false,
      },
    },
    x: {
      grid: {
        display: false,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
};

export default ({ days, timeRegistrations }) => {
  const theme = useTheme();

  const timeRegistrationsByDay = getTimeRegistrationsByDay(
    days,
    timeRegistrations
  );

  const chartValues = Object.values(timeRegistrationsByDay).map(
    (timeRegistrations) => {
      return aggregateMinutesFromTimeRegistrations(timeRegistrations) / 60;
    }
  );

  const data = {
    labels: days.map((day) => format(day, "EEEEEE")),
    datasets: [
      {
        data: chartValues,
        backgroundColor: theme.palette.primary.light,
      },
    ],
  };

  return (
    <Bar
      data={data}
      options={options}
      fallbackContent={a11ytext(days, chartValues)}
    />
  );
};
