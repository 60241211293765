import React from "react";

import { Grid, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import MainLayout from "../../layouts/MainLayout";
import PasswordResetForm from "../../containers/PasswordResetForm";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(5),
  },
  title: {
    marginBottom: theme.spacing(10),
  },
  form: {
    padding: theme.spacing(3),
  },
}));

export default ({ userDisplay }) => {
  const classes = useStyles();

  return (
    <MainLayout title="Account">
      <Grid container justify="center" className={classes.root}>
        <Grid item xs={12} className={classes.title}>
          <Typography variant="h2" component="h1" align="center">
            {userDisplay}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper className={classes.form}>
            <Typography variant="h4" component="h2">
              Change password
            </Typography>
            <PasswordResetForm />
          </Paper>
        </Grid>
      </Grid>
    </MainLayout>
  );
};
