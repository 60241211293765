import { compose } from "redux";
import { connect } from "react-redux";

import { getUserDetails } from "../../store/actions/user";

import AuthGate from "./AuthGate";

export default compose(
  connect(
    (state) => ({
      accessToken: state.user.accessToken,
    }),
    (dispatch) => ({
      refresh: () => dispatch(getUserDetails()),
    })
  )
)(AuthGate);
