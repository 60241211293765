import React, { useState } from "react";

import { format } from "date-fns";
import { useSnackbar } from "notistack";

import Api from "../../api";
import ReportForm from "../../components/ReportForm/ReportForm";

export default ({ onResult, ...props }) => {
  const [disableSubmit, setDisableSubmit] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (data) => {
    setDisableSubmit(true);

    try {
      const startDate =
        typeof data.start_date === "string"
          ? data.start_date
          : format(data.start_date, "yyyy-MM-dd");
      const endDate =
        typeof data.end_date === "string"
          ? data.end_date
          : format(data.end_date, "yyyy-MM-dd");

      const res = await Api.management.getReport(startDate, endDate);

      onResult(res);
    } catch (e) {
      enqueueSnackbar("Failed to create report", {
        variant: "error",
      });
    }

    setDisableSubmit(false);
  };

  return <ReportForm {...props} onSubmit={handleSubmit} />;
};
