import React from "react";

export default ({ icon = false, ...props }) => {
  if (icon) {
    return (
      <svg viewBox="0 0 101.7 101.7" {...props}>
        <path
          d="M50.8 0C22.8 0 0 22.8 0 50.8s22.8 50.8 50.8 50.8 50.8-22.8 50.8-50.8S78.9 0 50.8 0zm18.3 53.7L57.7 39.5l8.2-10.4c5.2 3.6 9 9 10.6 15.3l-7.4 9.3zm-6.8 8.6L50.8 76.7 25.2 44.4c1.6-6.3 5.4-11.7 10.6-15.3l26.5 33.2z"
          fill="#f20050"
        />
      </svg>
    );
  }

  return (
    <svg viewBox="0 0 364.04 101.7" {...props}>
      <path
        d="M159.52 67l-.68.68h-7.4l-.67-.68-11.44-25.6v-6.73L140 34h6.05l.67.67 8.08 20.86h.67l8.07-20.86.68-.67h6.05l.67.67v6.73zM187.44 28.52h-8.08l-.67-.67v-6.73l.67-.67h8.08l.67.67v6.73zm0 39h-8.08l-.67-.67V34.58l.67-.67h8.08l.67.67v32.29zM212.66 60.14c3.37 0 5.39-1.34 6.06-3.36l.67-.67h8.07l.68.67c-1.35 8.07-8.08 11.44-15.48 11.44-10.09 0-16.81-6.73-16.81-17.49s6.72-17.5 16.81-17.5c7.4 0 14.13 3.37 15.48 11.44l-.68.67h-8.07l-.67-.67c-.67-2-2.69-3.36-6.06-3.36-4.7 0-7.4 3.36-7.4 9.42s2.74 9.41 7.4 9.41zM265.48 67.54h-4l-1.35-.67-9.42-10.09H250l-4 4-.68 1.34v4.71l-.67.67h-8.07l-.68-.67V21.12l.68-.67h8.07l.67.67V48h.68l13.45-13.45 1.35-.67h4l.68.67v6.73l-8.08 8.07v.67l8.75 10.09v6.73zM286.67 57.45a1.78 1.78 0 002 2h6.06l.67.67v6.73l-.67.67h-6.06c-6.05 0-11.43-2.69-11.43-10.09V42.65l-.67-.67h-4.71l-.68-.67v-6.73l.68-.67h4.71l.67-.68v-6l.67-.67H286l.68.67v6l.67.68h7.4l.67.67v6.73l-.67.67h-7.4l-.67.67zM317.28 68.22c-10.09 0-16.82-6.73-16.82-17.49s6.73-17.5 16.82-17.5S334.1 40 334.1 50.73s-6.73 17.49-16.82 17.49zm0-26.91c-4.71 0-7.4 3.36-7.4 9.42s2.69 9.41 7.4 9.41 7.4-3.36 7.4-9.41-2.68-9.42-7.4-9.42zM363.36 42h-4.71c-5.38 0-7.4 2.69-7.4 8.75v16.12l-.67.67h-8.07l-.68-.67V34.58l.68-.67h7.4l.67.67.67 2h.68s1.34-2.69 7.4-2.69h4l.68.67v6.73zM50.85 0a50.85 50.85 0 1050.85 50.82A50.91 50.91 0 0050.85 0zm18.21 53.69l-11.4-14.24 8.23-10.37a26.6 26.6 0 0110.58 15.28zm-6.8 8.57L50.85 76.65 25.23 44.36a26.49 26.49 0 0110.61-15.3z"
        transform="translate(0 .03)"
        fill="#f20050"
      />
    </svg>
  );
};
