import axios from "axios";

export const getUserDetails = () => {
  return axios.get("/v1/user/");
};

export const login = (email, password) => {
  return axios.post("/v1/user/login/", { email, password });
};

export const resetPassword = (oldPassword, newPassword, newPasswordConfirm) => {
  return axios.post("/v1/user/reset-password/", {
    old_password: oldPassword,
    new_password: newPassword,
    new_password_confirm: newPasswordConfirm,
  });
};

export const requestPassword = (email) => {
  return axios.post("/v1/user/request-password/", { email });
};

export const resetPasswordFromEmail = (key, password) => {
  return axios.post("/v1/user/reset-password-from-email/", {
    reset_password_key: key,
    password,
  });
};
