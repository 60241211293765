import axios from "axios";

export function getTokenFromState(state) {
  return state.user.accessToken || null;
}

function configureApi(store, baseUrl = "/api") {
  axios.defaults.baseURL = baseUrl;

  axios.interceptors.request.use(async (config) => {
    const token = getTokenFromState(store.getState());

    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }

    config.headers["Accept"] = "application/json";
    config.headers["Content-Type"] = "application/json";

    return config;
  });
}

export default configureApi;
