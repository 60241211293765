import React from "react";

import { Grid, IconButton, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: "space-between",

    [theme.breakpoints.up("sm")]: {
      justifyContent: "space-evenly",
    },
  },
}));

export default ({
  title,
  onLargePreviousClick = Function.prototype,
  onLargeNextClick = Function.prototype,
  onSmallPreviousClick = Function.prototype,
  onSmallNextClick = Function.prototype,
}) => {
  const classes = useStyles();

  return (
    <Grid container alignItems="center" className={classes.root}>
      <Grid item>
        <IconButton onClick={onLargePreviousClick} aria-label="Previous week">
          <FirstPageIcon />
        </IconButton>
        <IconButton onClick={onSmallPreviousClick} aria-label="Previous day">
          <ChevronLeftIcon />
        </IconButton>
      </Grid>
      <Grid item>
        <Typography variant="subtitle1">{title}</Typography>
      </Grid>
      <Grid item>
        <IconButton onClick={onSmallNextClick} aria-label="Next day">
          <ChevronRightIcon />
        </IconButton>
        <IconButton onClick={onLargeNextClick} aria-label="Next week">
          <LastPageIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};
