import React, { useState } from "react";

import { Container, Fab } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AddIcon from "@material-ui/icons/Add";

import {
  format,
  getISOWeek,
  getYear,
  isToday,
  isTomorrow,
  isYesterday,
} from "date-fns";

import { getDaysAfterStartDate } from "../../utils/date";
import { aggregateMinutesFromTimeRegistrations } from "../../utils/entities";
import { convertMinutesToHourString } from "../../utils/text";

import MainLayout from "../../layouts/MainLayout";
import TimeRegistrationBlock from "../../components/TimeRegistration/TimeRegistrationBlock";
import TimeRegistrationFormDialog from "../../containers/TimeRegistrationFormDialog";
import ContactDialog from "../../components/ContactDialog";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(5),
    width: "100vw",
    maxWidth: "100vw",
    marginLeft: "calc(50% - 50vw)",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      maxWidth: "100%",
      marginLeft: 0,
    },
  },
  addButton: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),

    [theme.breakpoints.up("sm")]: {
      bottom: theme.spacing(5),
      right: theme.spacing(5),
    },
  },
}));

export default ({
  targetStartOfWeek,
  timeRegistrations,
  onWeekChange,
  onEditItem,
  onDeleteItem,
  projectParticipants,
}) => {
  const [showAddFormDialog, setShowAddFormDialog] = useState(false);
  const [showContactDialog, setShowContactDialog] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const [selectedTabIndex, setSelectedTabIndex] = useState(null);

  const weekNumber = getISOWeek(targetStartOfWeek, { weekStartsOn: 1 });

  const days = [
    targetStartOfWeek,
    ...getDaysAfterStartDate(targetStartOfWeek, 7),
  ];

  const getControlsDisplayTitle = () => {
    const currentYear = getYear(new Date());
    const targetYear = getYear(targetStartOfWeek);
    const totalHoursInWeekFormatted = convertMinutesToHourString(
      aggregateMinutesFromTimeRegistrations(timeRegistrations)
    );

    return currentYear === targetYear
      ? `Week ${weekNumber} | ${totalHoursInWeekFormatted}`
      : `Week ${weekNumber}, ${targetYear} | ${totalHoursInWeekFormatted}`;
  };

  const getLayoutTitle = () => {
    const defaultTitle = "Time Registration";
    if (selectedTabIndex === null) return defaultTitle;

    const selectedDate = days[selectedTabIndex];
    if (!selectedDate) return defaultTitle;

    if (isToday(selectedDate)) {
      return "Today";
    } else if (isYesterday(selectedDate)) {
      return "Yesterday";
    } else if (isTomorrow(selectedDate)) {
      return "Tomorrow";
    }

    return format(selectedDate, "MMMM do");
  };

  const openCreateDialog = () => {
    if (!projectParticipants) {
      setShowContactDialog(true);
    } else {
      setShowAddFormDialog(true);
    }
  };

  return (
    <MainLayout title={getLayoutTitle()}>
      <Fab
        aria-label="Add time registration"
        color="primary"
        className={classes.addButton}
        variant={isDesktop ? "extended" : "round"}
        onClick={openCreateDialog}
      >
        <AddIcon />
        {isDesktop && <span aria-hidden="true">Add time registration</span>}
      </Fab>
      <Container maxWidth="sm" className={classes.container}>
        <TimeRegistrationBlock
          days={days}
          timeRegistrations={timeRegistrations}
          controlsTitle={getControlsDisplayTitle()}
          onLargePreviousClick={() => onWeekChange(-1)}
          onLargeNextClick={() => onWeekChange(1)}
          onEditItem={onEditItem}
          onDeleteItem={onDeleteItem}
          onTabIndexChange={(tabIndex) => setSelectedTabIndex(tabIndex)}
          projectParticipants={projectParticipants}
          onEmptyAddButtonClick={() => setShowAddFormDialog(true)}
        />
      </Container>
      <TimeRegistrationFormDialog
        open={showAddFormDialog}
        onClose={() => setShowAddFormDialog(false)}
        defaultDate={days[selectedTabIndex || 0]}
        clearValuesAfterSubmit={true}
        persistValuesAfterClear={["paricipant", "role"]}
      />
      <ContactDialog
        open={showContactDialog}
        onClose={() => setShowContactDialog(false)}
        title="No projects found that are linked to your account"
        description="Please get in contact with Vicktor if your project is not visible:"
      />
    </MainLayout>
  );
};
