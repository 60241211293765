import React, { useState } from "react";

import { connect } from "react-redux";

import Contracts from "./Contracts";

import { getContractList } from "../../store/actions/management";
import { selectAllContracts } from "../../store/selectors/management";

function ContractsContainer({ contracts, loadContracts }) {
  return <Contracts contracts={contracts} loadContracts={loadContracts} />;
}

export default connect(
  (state) => ({
    contracts: selectAllContracts(state),
  }),
  (dispatch) => ({
    loadContracts: () => dispatch(getContractList()),
  })
)(ContractsContainer);
