import React, { useState } from "react";

import { Link } from "react-router-dom";

import {
  AppBar,
  Container,
  Drawer,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuIcon from "@material-ui/icons/Menu";

import DrawerContent from "../../components/DrawerContent";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
}));

function MainLayout({ title, children, logout }) {
  const classes = useStyles();

  const [accountAnchorEl, setAccountAnchorEl] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);

  const toggleDrawerOpen = () => {
    setOpenDrawer((currentValue) => !currentValue);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            className={classes.menuButton}
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawerOpen}
          >
            <MenuIcon />
          </IconButton>
          <Drawer anchor="left" open={openDrawer} onClose={handleDrawerClose}>
            <DrawerContent />
          </Drawer>
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
          <IconButton
            color="inherit"
            edge="end"
            aria-label="Account menu"
            aria-controls="account-menu"
            aria-haspopup="true"
            onClick={(event) => setAccountAnchorEl(event.currentTarget)}
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="account-menu"
            anchorEl={accountAnchorEl}
            keepMounted
            open={Boolean(accountAnchorEl)}
            onClose={() => setAccountAnchorEl(null)}
          >
            <MenuItem component={Link} to="/account/">
              Account
            </MenuItem>
            <MenuItem onClick={logout}>Logout</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="lg">
        {children}
      </Container>
    </div>
  );
}

export default MainLayout;
