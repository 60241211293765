import React from "react";

import { useController } from "react-hook-form";

import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";

export default ({
  control,
  id,
  name,
  label,
  defaultValue = "",
  options,
  controllerProps,
  selectProps,
  ...props
}) => {
  const { field } = useController({
    name,
    control,
    defaultValue,
    ...controllerProps,
  });

  return (
    <FormControl variant="outlined" {...props}>
      <InputLabel id={`${id}-label`}>{label}</InputLabel>
      <Select
        labelId={`${id}-label`}
        id={id}
        label={label}
        defaultValue={field.value}
        disabled={!options.length}
        {...selectProps}
        inputRef={field.ref}
        {...field}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
