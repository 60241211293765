import { format } from "date-fns";

import { convertHourStringToMinutes, isValidHourStringFormat } from "./text";

export const parseTimeRegistrationFormData = (data, projectParticipants) => {
  return {
    participant: data.participant,
    role: data.role,
    date: format(data.date, "yyyy-MM-dd"),
    minutes: isValidHourStringFormat(data.hours)
      ? convertHourStringToMinutes(data.hours)
      : Number(data.hours) * 60,
    note: data.note,
  };
};
