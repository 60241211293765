import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
} from "@material-ui/core";

export default ({
  open,
  onClose,
  title,
  description,
  buttonText = "Ok",
  ...props
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="contact-dialog-title"
      aria-describedby="contact-dialog-text"
      {...props}
    >
      <DialogTitle id="contact-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="contact-dialog-text">
          {description}
          <br />
          <br />
          Contact Vicktor on&nbsp;
          <Link
            target="_blank"
            rel="noreferrer noopener"
            href="slack://user?team=TPB22EAVD&id=USHBY5E7R"
          >
            Slack
          </Link>
          <br />
          Or call Vicktor on&nbsp;
          <Link rel="noreferrer noopener" href="tel:+31619390691">
            0619390691
          </Link>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{buttonText}</Button>
      </DialogActions>
    </Dialog>
  );
};
