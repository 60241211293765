import React from "react";
import ReactDOM from "react-dom";

import CssBaseline from "@material-ui/core/CssBaseline";

import App from "./App";
import "./styles/index.scss";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

ReactDOM.render(
  <>
    <CssBaseline />
    <App />
  </>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: () => {
    const reload = window.confirm(
      "Update installed. Close the app and re-launch it in order to see the latest changes."
    );

    if (reload) {
      window.location.reload();
    }
  },
});
