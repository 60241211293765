import React from "react";

import { Grid, Typography, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { Link as RouterLink } from "react-router-dom";

import MainLayout from "../../layouts/MainLayout";
import WeekHourGraph from "../../components/WeekHourGraph";
import { convertMinutesToHourString } from "../../utils/text";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(5),
  },
  alert: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  chartPaper: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));

export default ({ userDisplay, timeRegistrations, timeRegistrationsByProject, timeRegistrationDays }) => {
  const classes = useStyles();

  return (
    <MainLayout title="Dashboard">
      <Grid container justify="center" className={classes.root}>
        <Grid item>
          <Typography align="center" variant="h4">
            Hi{userDisplay && ","}
          </Typography>
          {userDisplay && (
            <Typography align="center">
              {userDisplay}
            </Typography>
          )}
          <Alert severity="info" className={classes.alert}>
            <AlertTitle>New feature</AlertTitle>
            View your current contract(s) —{" "}
            <Link to="/contracts/" component={RouterLink}>
              <strong>check it out</strong>
            </Link>
          </Alert>
          <Paper className={classes.chartPaper}>
            <Typography align="center">Hours this week</Typography>
            <WeekHourGraph
              timeRegistrations={timeRegistrations}
              days={timeRegistrationDays}
            />
          </Paper>
          {timeRegistrationsByProject.length && (
            <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>This week</TableCell>
                      <TableCell align="right">Hours</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {timeRegistrationsByProject.map(([projectName, totalMinutes]) => (
                      <TableRow key={projectName}>
                        <TableCell>{projectName}</TableCell>
                        <TableCell align="right">{convertMinutesToHourString(totalMinutes)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
            </TableContainer>
          )}
        </Grid>
      </Grid>
    </MainLayout>
  );
};
