import * as managementApi from "./management";
import * as secretsApi from "./secrets";
import * as userApi from "./user";

const Api = {
  management: { ...managementApi },
  secrets: { ...secretsApi },
  user: { ...userApi },
};

export default Api;
