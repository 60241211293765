import React, { useState } from "react";

import { useSnackbar } from "notistack";

import { getDrfErrorMessages } from "../../utils/errors";

import PasswordResetForm from "../../components/PasswordResetForm";

export default ({ resetPassword }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [errors, setErrors] = useState({});
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const onSubmit = async (data) => {
    setDisableSubmitButton(true);

    try {
      const action = await resetPassword(
        data.oldPassword,
        data.newPassword,
        data.newPasswordConfirm
      );
      if (!action.error) {
        setErrors({});
        enqueueSnackbar("Successfully changed password", {
          variant: "success",
        });
      }
    } catch (err) {
      setErrors(
        err?.data || { detail: "Something went wrong. Please try again." }
      );
    }

    setDisableSubmitButton(false);
  };

  return (
    <PasswordResetForm
      onSubmit={onSubmit}
      errorMessages={getDrfErrorMessages(errors)}
      disableSubmitButton={disableSubmitButton}
    />
  );
};
