import React from "react";

import { Button, List, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import TimeRegistrationListItem from "../TimeRegistrationListItem";

const useStyles = makeStyles((theme) => ({
  emptyTabPanel: {
    marginTop: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  emptyAddButton: {
    marginTop: theme.spacing(2),
  },
  hidden: {
    display: "none",
  },
}));

function TimeRegistrationTabPanel({
  timeRegistrations,
  onEditItem,
  onDeleteItem,
  projectParticipants = [],
  onEmptyAddButtonClick = Function.prototype,
  hidden = false,
  ...props
}) {
  const classes = useStyles();

  if (!timeRegistrations || !timeRegistrations.length) {
    return (
      <div className={hidden ? classes.hidden : classes.emptyTabPanel}>
        <Typography variant="body1">
          No time registrations for this day
        </Typography>
        <Button
          className={classes.emptyAddButton}
          variant="outlined"
          onClick={onEmptyAddButtonClick}
        >
          Add time registration
        </Button>
      </div>
    );
  }

  return (
    <List hidden={hidden} {...props}>
      {timeRegistrations.map((timeRegistration) => (
        <TimeRegistrationListItem
          key={timeRegistration.id}
          timeRegistration={timeRegistration}
          onEdit={(data) => onEditItem(timeRegistration.id, data)}
          onDelete={() => onDeleteItem(timeRegistration.id)}
          projectParticipants={projectParticipants}
        />
      ))}
    </List>
  );
}

export default TimeRegistrationTabPanel;
