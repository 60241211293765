import React from "react";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import { HashRouter as Router, Switch } from "react-router-dom";

import { ThemeProvider } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";

import { PublicRoute, PrivateRoute } from "./routes/auth";
import Account from "./routes/Account";
import Dashboard from "./routes/Dashboard";
import Contracts from "./routes/Contracts";
import PageNotFound from "./routes/PageNotFound";
import Reports from "./routes/Reports";
import Login from "./routes/Login";
import Secrets from "./routes/Secrets";

import theme from "./theme";
import configureApi from "./api/configureApi";
import AuthGate from "./hocs/AuthGate";
import configureStore from "./store/configureStore";
import TimeRegistration from "./routes/TimeRegistration";
import SnackbarProvider from "./hocs/SnackbarProvider";

const API_BASE_URL =
  process.env.NODE_ENV === "production"
    ? "https://vicktor.nl/api"
    : "http://localhost:8000/api";

const { store, persistor } = configureStore();

configureApi(store, API_BASE_URL);

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <AuthGate>
              <Router>
                <SnackbarProvider>
                  <Switch>
                    <PrivateRoute exact path="/" component={Dashboard} />
                    <PrivateRoute
                      exact
                      path="/contracts/"
                      component={Contracts}
                    />
                    <PrivateRoute
                      exact
                      path="/time-registration/"
                      component={TimeRegistration}
                    />
                    <PrivateRoute exact path="/reports/" component={Reports} />
                    <PrivateRoute exact path="/secrets/" component={Secrets} />
                    <PrivateRoute exact path="/account/" component={Account} />
                    <PublicRoute exact path="/login/" component={Login} />
                    <PublicRoute component={PageNotFound} />
                  </Switch>
                </SnackbarProvider>
              </Router>
            </AuthGate>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
