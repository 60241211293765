import React, { useEffect, useState } from "react";

import { connect } from "react-redux";

import {
  addDays,
  addWeeks,
  format,
  startOfWeek as getStartOfWeek,
} from "date-fns";

import { parseTimeRegistrationFormData } from "../../utils/data";

import {
  deleteTimeRegistration,
  getProjectParticipantList,
  getTimeRegistrationList,
  updateTimeRegistration,
} from "../../store/actions/management";
import {
  selectAllProjectParticipants,
  selectTimeRegistrationsByDateRange,
} from "../../store/selectors/management";
import TimeRegistration from "./TimeRegistration";

const DATE_FORMAT = "yyyy-MM-dd";

function TimeRegistrationContainer({
  deleteTimeRegistration,
  getTimeRegistrations,
  updateTimeRegistrationList,
  projectParticipants,
  loadProjectParticipants,
  editTimeRegistration,
}) {
  const [targetStartOfWeek, setTargetStartOfWeek] = useState(
    getStartOfWeek(new Date(), { weekStartsOn: 1 })
  );

  const addWeeksToTargetDate = (amount) => {
    setTargetStartOfWeek((currentTargetDate) => {
      return addWeeks(currentTargetDate, amount);
    });
  };

  useEffect(() => {
    updateTimeRegistrationList(
      addWeeks(targetStartOfWeek, -1),
      addWeeks(targetStartOfWeek, 2)
    );
  }, [targetStartOfWeek, updateTimeRegistrationList]);

  useEffect(() => {
    loadProjectParticipants();
  }, [loadProjectParticipants]);

  const timeRegistrations = getTimeRegistrations(
    targetStartOfWeek,
    addDays(targetStartOfWeek, 6)
  );

  const handleEditTimeRegistration = async (id, data) => {
    const parsedData = parseTimeRegistrationFormData(data, projectParticipants);
    return await editTimeRegistration(id, parsedData);
  };

  return (
    <TimeRegistration
      targetStartOfWeek={targetStartOfWeek}
      timeRegistrations={timeRegistrations}
      onWeekChange={addWeeksToTargetDate}
      onEditItem={handleEditTimeRegistration}
      onDeleteItem={deleteTimeRegistration}
      projectParticipants={projectParticipants}
    />
  );
}

export default connect(
  (state) => ({
    projectParticipants: selectAllProjectParticipants(state),
    getTimeRegistrations: (startDate, endDate) =>
      selectTimeRegistrationsByDateRange(startDate, endDate)(state),
  }),
  (dispatch) => ({
    updateTimeRegistrationList: (startDate = null, endDate = null) => {
      if (startDate && endDate) {
        const startDateStr = format(startDate, DATE_FORMAT);
        const endDateStr = format(endDate, DATE_FORMAT);
        return dispatch(getTimeRegistrationList(startDateStr, endDateStr));
      }

      return dispatch(getTimeRegistrationList());
    },
    editTimeRegistration: (id, data) =>
      dispatch(updateTimeRegistration(id, data)),
    deleteTimeRegistration: (id) => dispatch(deleteTimeRegistration(id)),
    loadProjectParticipants: () => dispatch(getProjectParticipantList()),
  })
)(TimeRegistrationContainer);
