import { createAction } from "redux-actions";

import Api from "../../api";

export const getUserDetails = createAction("user/details", () =>
  Api.user.getUserDetails()
);

export const login = createAction("user/login", (email, password) =>
  Api.user.login(email, password).catch((res) => Promise.reject(res.response))
);

export const logout = createAction("user/logout");

export const resetPassword = createAction(
  "user/reset-password",
  (oldPassword, newPassword, newPasswordConfirm) =>
    Api.user
      .resetPassword(oldPassword, newPassword, newPasswordConfirm)
      .catch((res) => Promise.reject(res.response))
);

export const requestPassword = createAction("user/request-password", (email) =>
  Api.user.requestPassword(email).catch((res) => Promise.reject(res.response))
);

export const resetPasswordFromEmail = createAction(
  "user/reset-password-from-email",
  (key, password) =>
    Api.user
      .resetPassword(key, password)
      .catch((res) => Promise.reject(res.response))
);
