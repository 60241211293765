import React, { useEffect, useState } from "react";

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import produce from "immer";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CloseIcon from "@material-ui/icons/Close";

import HookFormSelect from "../../Inputs/HookFormSelect";
import HoursInput from "../../Inputs/HoursInput";

import { getHoursDisplayValue, isValidHourFormat } from "../../../utils/text";
import HookFormKeyboardDatePicker from "../../Inputs/HookFormKeyboardDatePicker";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogActions: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),

    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
}));

const FIELD_REQUIRED_TEXT = "This field is required";

const schema = yup.object().shape({
  participant: yup.number().required(FIELD_REQUIRED_TEXT),
  role: yup.number().required(FIELD_REQUIRED_TEXT),
  hours: yup
    .string()
    .test("valid-time", "This must be a valid time", (value) =>
      isValidHourFormat(value)
    )
    .required(FIELD_REQUIRED_TEXT),
  date: yup
    .date()
    .typeError("This must be a valid date")
    .required(FIELD_REQUIRED_TEXT),
  note: yup.string(),
});

export default ({
  open,
  onSubmit,
  onParticipantSelect = Function.prototype,
  onClose,
  title = "New time registration",
  submitButtonText = "Create",
  defaultValues = {},
  projectOptions = [],
  roleOptions = [],
  disableSubmit = false,
  clearValuesAfterSubmit = false,
  persistValuesAfterClear = [],
  defaultDate = new Date(),
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const classes = useStyles();

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setFocus,
    getValues,
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const participantSelectValue = watch("participant");
  const dateSelectValue = watch("date");

  const [oldParticipantSelectValue, setOldParticipantSelectValue] = useState(
    null
  );

  // On project/date select input value change
  useEffect(() => {
    // Only clear if user has selected new project
    if (
      oldParticipantSelectValue &&
      participantSelectValue !== oldParticipantSelectValue
    ) {
      setValue("role", roleOptions.length ? roleOptions[0] : "");
    } else {
      if (roleOptions.length) {
        setValue("role", roleOptions[0].value);
      }
    }

    onParticipantSelect(participantSelectValue);
    setOldParticipantSelectValue(participantSelectValue);
  }, [
    participantSelectValue,
    onParticipantSelect,
    setValue,
    defaultValues,
    oldParticipantSelectValue,
    setOldParticipantSelectValue,
  ]);

  const resetForm = (data) => {
    if (clearValuesAfterSubmit) {
      const newValues = produce(data, (draftValues) => {
        Object.keys(draftValues)
          .filter((key) => !persistValuesAfterClear.includes(key))
          .forEach((key) => {
            delete draftValues[key];
          });
        return draftValues;
      });

      reset({ ...newValues });
    } else {
      reset({ ...data, hours: getHoursDisplayValue(data.hours) });
    }
  };

  const handleOnSubmit = async (data) => {
    await onSubmit(data);
    resetForm(data);
  };

  const handleOnEntering = () => {
    const participant = getValues("participant");
    const role = getValues("role");
    const hours = getValues("hours");

    if (participant && role && !hours) {
      setFocus("hours");
    }
  };

  const handleOnExited = () => {
    const data = getValues();
    resetForm(data);
  };

  const date = dateSelectValue || defaultDate;

  // Filter based on date (note: only days are taken into account)
  const filteredParticipantOptions = projectOptions.filter((obj) => {
    if (
      obj.dateStart &&
      new Date(new Date(obj.dateStart).toDateString()) > date
    ) {
      return false;
    }

    if (obj.dateEnd && new Date(new Date(obj.dateEnd).toDateString()) < date) {
      return false;
    }

    return true;
  });

  return (
    <Dialog
      open={open}
      fullScreen={!isDesktop}
      onClose={onClose}
      aria-labelledby="time-registration-form-dialog-title"
      onEntering={handleOnEntering}
      onExited={handleOnExited}
    >
      <DialogTitle id="time-registration-form-dialog-title">
        {title}
        {onClose && (
          <IconButton
            aria-label="Close"
            onClick={onClose}
            className={classes.closeButton}
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent dividers>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box width={1}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <HookFormKeyboardDatePicker
                  name="date"
                  label="Date"
                  variant="dialog"
                  inputVariant="outlined"
                  defaultDate={defaultDate}
                  control={control}
                  error={Boolean(errors?.date)}
                  helperText={errors?.date?.message}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <HookFormSelect
                  id="time-registration-participant-select"
                  control={control}
                  error={Boolean(errors?.participant)}
                  name="participant"
                  label="Project"
                  options={filteredParticipantOptions}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <HookFormSelect
                  id="time-registration-role-select"
                  control={control}
                  error={Boolean(errors?.role)}
                  name="role"
                  label="Role"
                  fullWidth
                  options={roleOptions}
                />
              </Grid>
              <Grid item xs={12}>
                <HoursInput
                  name="hours"
                  control={control}
                  error={Boolean(errors?.hours)}
                  helperText={errors?.hours?.message}
                  label="Hours"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="note"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label="Note (optional)"
                      variant="outlined"
                      name={field.name}
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      inputRef={field.ref}
                      inputProps={{ spellCheck: false }}
                      multiline
                      fullWidth
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
          {/* Hidden submit button to allow submit on enter key since actual submit button is outside the form */}
          <button type="submit" style={{ display: "none" }}>
            {submitButtonText}
          </button>
        </form>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          fullWidth
          onClick={handleSubmit(handleOnSubmit)}
          variant="contained"
          color="primary"
          disabled={disableSubmit}
        >
          {submitButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
