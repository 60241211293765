import React from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Box, Button, Checkbox, Grid, TextField } from "@material-ui/core";

import ErrorMessage from "../ErrorMessage";

const schema = yup.object().shape({
  value: yup.string().required("You need to have content to make a secret"),
});

export default ({
  onSubmit,
  disableSubmitButton = false,
  errorMessages = [],
}) => {
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box width={1}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {errorMessages.map((message, index) => (
              <ErrorMessage key={index} message={message} />
            ))}
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              placeholder="Secret content here"
              variant="outlined"
              {...register("value")}
              rows={6}
              rowsMax={28}
              error={Boolean(errors?.value)}
              helperText={errors?.value?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              type="submit"
              color="primary"
              variant="contained"
              disabled={disableSubmitButton}
            >
              Create Secret
            </Button>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};
