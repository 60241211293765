import React from "react";

import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Box, Button, Grid, TextField } from "@material-ui/core";

import ErrorMessage from "../ErrorMessage";

const FIELD_REQUIRED_TEXT = "This field is required";

const schema = yup.object().shape({
  oldPassword: yup.string().required(FIELD_REQUIRED_TEXT),
  newPassword: yup
    .string()
    .required(FIELD_REQUIRED_TEXT)
    .min(9, "New password must be at least ${min} characters long"),
  newPasswordConfirm: yup
    .string()
    .required(FIELD_REQUIRED_TEXT)
    .equals([yup.ref("newPassword")], "Password confirmation doesn't match"),
});

function PasswordResetForm({
  onSubmit,
  errorMessages = [],
  disableSubmitButton,
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box width={1}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {errorMessages.map((message, index) => (
              <ErrorMessage key={index} message={message} />
            ))}
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Current Password"
              variant="outlined"
              type="password"
              {...register("oldPassword")}
              error={Boolean(errors?.oldPassword)}
              helperText={errors?.oldPassword?.message}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="New Password"
              variant="outlined"
              type="password"
              {...register("newPassword")}
              error={Boolean(errors?.newPassword)}
              helperText={errors?.newPassword?.message}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="newPasswordConfirm"
              label="Confirm new Password"
              variant="outlined"
              type="password"
              {...register("newPasswordConfirm")}
              error={Boolean(errors?.newPasswordConfirm)}
              helperText={errors?.newPasswordConfirm?.message}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={disableSubmitButton}
              fullWidth
            >
              Change password
            </Button>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
}

export default PasswordResetForm;
