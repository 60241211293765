import React, { useState } from "react";

import { Redirect } from "react-router-dom";

import { getDrfErrorMessages } from "../../utils/errors";

import LoginForm from "../../components/LoginForm";

export default ({ login }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [errors, setErrors] = useState({});

  const onSubmit = async (data) => {
    try {
      const action = await login(data);
      if (!action.error) {
        setIsLoggedIn(true);
      }
    } catch (err) {
      setErrors(
        err?.data || { detail: "Something went wrong. Please try again." }
      );
    }
  };

  if (isLoggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <LoginForm
      onSubmit={onSubmit}
      errorMessages={getDrfErrorMessages(errors)}
    />
  );
};
