import { createSelector } from "reselect";

const selectUser = (state) => state.user;

export const isAuthenticated = createSelector(
  selectUser,
  (user) => user.email !== null && user.accessToken !== null
);

export const userDisplaySelector = createSelector(selectUser, (user) => {
  return user.firstName || user.lastName
    ? `${user.firstName} ${user.lastName}`
    : null;
});
