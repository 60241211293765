import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import { SnackbarProvider } from "notistack";

const useStyles = makeStyles((theme) => ({
  snack: {
    bottom: "10%",
    [theme.breakpoints.up("sm")]: {
      bottom: "14px",
    },
  },
  snackSuccess: {
    backgroundColor: `${theme.palette.success.main} !important`,
  },
  snackError: {
    backgroundColor: `${theme.palette.error.main} !important`,
  },
  snackWarning: {
    backgroundColor: `${theme.palette.warning.main} !important`,
  },
  snackInfo: {
    backgroundColor: `${theme.palette.info.main} !important`,
  },
}));

export default ({ children }) => {
  const classes = useStyles();

  return (
    <SnackbarProvider
      maxSnack={1}
      classes={{
        containerRoot: classes.snack,
        variantSuccess: classes.snackSuccess,
        variantError: classes.snackError,
        variantInfo: classes.snackInfo,
      }}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
    >
      {children}
    </SnackbarProvider>
  );
};
