import { createAction } from "redux-actions";

import Api from "../../api";

export const getTimeRegistrationList = createAction(
  "timeregistration/list",
  (startDateStr, endDateStr) =>
    Api.management.getTimeRegistrationList({
      start_date: startDateStr,
      end_date: endDateStr,
    })
);

export const createTimeRegistration = createAction(
  "timeregistration/create",
  (data) => Api.management.createTimeRegistration(data)
);

export const updateTimeRegistration = createAction(
  "timeregistration/update",
  (id, data) => Api.management.updateTimeRegistration(id, data)
);

export const deleteTimeRegistration = createAction(
  "timeregistration/delete",
  (id) => Api.management.deleteTimeRegistration(id),
  (id) => ({ delete: true, id: id })
);

export const getProjectParticipantList = createAction(
  "projectparticipant/list",
  () => Api.management.getProjectParticipantList()
);

export const getContractList = createAction("contract/list", () =>
  Api.management.getContractList()
);
