import React from "react";

import { Redirect } from "react-router-dom";

import { Container, makeStyles, Typography } from "@material-ui/core";

import LoginForm from "../../containers/LoginForm";
import Logo from "../../components/Logo";

const useStyles = makeStyles((theme) => ({
  items: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "5rem",
  },
  form: {
    marginTop: theme.spacing(4),
  },
  logo: {
    height: "6rem",
    marginBottom: theme.spacing(2),
  },
}));

export default ({ isAlreadyLoggedIn }) => {
  const classes = useStyles();

  if (isAlreadyLoggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.items}>
        <Logo icon className={classes.logo} />
        <Typography>Welcome to My Vicktor</Typography>
        <Typography component="h1" variant="h2">
          Login
        </Typography>
        <div className={classes.form}>
          <LoginForm />
        </div>
      </div>
    </Container>
  );
};
