import { connect } from "react-redux";

import { createTimeRegistration } from "../../store/actions/management";
import { selectAllProjectParticipants } from "../../store/selectors/management";

import TimeRegistrationFormDialog from "./TimeRegistrationFormDialog";

export default connect(
  (state) => ({
    projectParticipants: selectAllProjectParticipants(state),
  }),
  (dispatch) => ({
    onCreateItem: (data) => dispatch(createTimeRegistration(data)),
  })
)(TimeRegistrationFormDialog);
