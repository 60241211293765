import React from "react";

import { Grid, Tab, Typography } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { format } from "date-fns";

import { convertMinutesToHourString } from "../../../utils/text";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "2.7rem",
    textAlign: "center",

    [theme.breakpoints.up("sm")]: {
      textAlign: "left",
    },
  },
  dayNameText: {
    fontSize: "0.75rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "inherit",
    },
  },
  totalHoursMobile: {
    textTransform: "none",
  },
}));

export default ({ tabDate, minutes, ...props }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const classes = useStyles();

  const dayName = format(tabDate, "EEEEEE");
  const dayInMonth = format(tabDate, "d");

  return (
    <Tab
      className={classes.root}
      {...props}
      fullWidth
      label={
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="subtitle2" className={classes.dayNameText}>
              {dayName}
              {isDesktop && `, ${dayInMonth}`}
            </Typography>
          </Grid>
          {!isDesktop && (
            <Grid item xs={12} sm={6}>
              <Typography>{dayInMonth}</Typography>
              <Typography
                className={classes.totalHoursMobile}
                variant="caption"
              >
                {minutes / 60}h
              </Typography>
            </Grid>
          )}
          {isDesktop && (
            <Grid item xs={12}>
              <Typography variant="subtitle2">
                {convertMinutesToHourString(minutes)}
              </Typography>
            </Grid>
          )}
        </Grid>
      }
    />
  );
};
