import React, { useEffect, useState } from "react";

import {
  Button,
  Container,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";

import MainLayout from "../../layouts/MainLayout";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(5),
  },
  title: {
    marginBottom: theme.spacing(10),
  },
  paper: {
    padding: theme.spacing(3),
  },
  button: {
    marginTop: theme.spacing(2),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  secondaryAction: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
}));

const getContractTitle = (contract) => {
  let result = contract.client.name  

  if (contract.project) {
    result += ` (${contract.project.name})`
  }

  return result
}

const getContractDateString = (dateStart, dateEnd) => {
  if (dateStart && dateEnd) {
    return `${dateStart} - ${dateEnd}`;
  } else if (dateStart) {
    return `From ${dateStart}`;
  } else if (dateEnd) {
    return `Till ${dateEnd}`;
  }

  return "This is an open-onded contract";
};

export default ({ contracts, loadContracts }) => {
  const classes = useStyles();

  useEffect(() => {
    loadContracts();
  }, [loadContracts]);

  return (
    <MainLayout title="Contracts">
      <Grid container justify="center" className={classes.root}>
        <Grid item xs={12} md={6}>
          <Paper className={classes.paper}>
            <Typography variant="h6" component="h1">
              Contracts
            </Typography>
            {contracts && contracts.length ? (
              <List>
                {contracts.map((contract) => (
                  <ListItem key={contract.id}>
                    <Grid container>
                      <Grid item>
                        <Typography variant="subtitle2">
                          {getContractTitle(contract)}
                        </Typography>
                        <Grid container direction="row" alignItems="center">
                          <CalendarTodayIcon className={classes.icon} />{" "}
                          {getContractDateString(
                            contract.date_start,
                            contract.date_end
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <ListItemSecondaryAction
                      className={classes.secondaryAction}
                    >
                      <Typography>
                        {`${contract.hours_per_week} hours per week`}
                      </Typography>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            ) : (
              <Alert severity="info" className={classes.alert}>
                <AlertTitle>No active contract found at the moment</AlertTitle>
                Please get in contact with Vicktor if this is not correct
              </Alert>
            )}
          </Paper>
        </Grid>
      </Grid>
    </MainLayout>
  );
};
