import React from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Box, Button, Grid, TextField } from "@material-ui/core";

import ErrorMessage from "../ErrorMessage";

const schema = yup.object().shape({
  email: yup
    .string()
    .email("This email is not valid")
    .required("This field is required"),
  password: yup.string().required("This field is required"),
});

function LoginForm({ onSubmit, errorMessages = [] }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Box width={1}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {errorMessages.map((message, index) => (
              <ErrorMessage key={index} message={message} />
            ))}
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              type="email"
              error={Boolean(errors?.email)}
              helperText={errors?.email?.message}
              {...register("email")}
              label="Email"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              type="password"
              error={Boolean(errors?.password)}
              helperText={errors?.password?.message}
              {...register("password")}
              label="Password"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <Button fullWidth type="submit" variant="contained" color="primary">
              Login
            </Button>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
}

export default LoginForm;
