import React from "react";

import { Route, Redirect } from "react-router-dom";

import { compose } from "redux";
import { connect } from "react-redux";

import { isAuthenticated } from "../../store/selectors/user";

const PrivateRoute = ({ component: Component, authenticated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!authenticated) {
          return <Redirect to="/login/" />;
        }

        return <Component {...props} />;
      }}
    />
  );
};

export default compose(
  connect((state) => ({
    authenticated: isAuthenticated(state),
  }))
)(PrivateRoute);
