import React from "react";

import {
  Button,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import MainLayout from "../../layouts/MainLayout";
import SecretCreateForm from "../../components/SecretCreateForm";
import ClipboardResult from "../../components/ClipboardResult";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(5),
    width: "100vw",
    maxWidth: "100vw",
    marginLeft: "calc(50% - 50vw)",

    [theme.breakpoints.up("sm")]: {
      width: "100%",
      maxWidth: "100%",
      marginLeft: 0,
      display: "flex",
      justifyContent: "center",
    },
  },
  paper: {
    padding: theme.spacing(3),
    width: "100%",

    [theme.breakpoints.up("sm")]: {
      width: "35rem",
    },
  },
}));

export default ({
  secretValue,
  secretUrl,
  onSubmit,
  clearSecretValues,
  disableCreateSubmitButton = false,
}) => {
  const classes = useStyles();

  const showResultComponent = Boolean(secretValue && secretUrl);

  const CreateComponent = () => (
    <Container maxWidth="sm" className={classes.container}>
      <Paper className={classes.paper}>
        <Typography variant="h6" component="h1">
          Paste a password, secret message or private link below.
        </Typography>
        <SecretCreateForm
          onSubmit={(data) => onSubmit(data)}
          disableSubmitButton={disableCreateSubmitButton}
        />
      </Paper>
    </Container>
  );

  const ResultComponent = () => (
    <Container maxWidth="sm" className={classes.container}>
      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              component="label"
              htmlFor="secrets-clipboard-result"
            >
              Share this link
            </Typography>
            <ClipboardResult
              inputId="secrets-clipboard-result"
              content={secretUrl}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              component="label"
              htmlFor="secrets-secret-result"
            >
              Secret
            </Typography>
            <TextField
              fullWidth
              multiline
              InputProps={{
                readOnly: true,
              }}
              id="secrets-secret-result"
              variant="outlined"
              rows={6}
              rowsMax={28}
              value={secretValue}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={clearSecretValues}
            >
              Create another secret
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );

  return (
    <>
      <MainLayout title="Secrets">
        {showResultComponent ? <ResultComponent /> : <CreateComponent />}
      </MainLayout>
    </>
  );
};
