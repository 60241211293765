import React, { useState } from "react";

import Api from "../../api";

import { getSecretUrlFromUrlPart } from "../../utils/urls";

import Secrets from "./Secrets";

function SecretsContainer() {
  const [secretValue, setSecretValue] = useState("");
  const [secretUrl, setSecretUrl] = useState("");
  const [disableCreateSubmitButton, setDisableCreateSubmitButton] = useState(
    false
  );

  const clearSecretValues = () => {
    setSecretValue("");
    setSecretUrl("");
  };

  const onSubmit = async (data) => {
    setDisableCreateSubmitButton(true);

    const response = await Api.secrets.createSecret(data.value);

    setSecretValue(data.value);
    setSecretUrl(getSecretUrlFromUrlPart(response.data.url_part));

    setDisableCreateSubmitButton(false);
  };

  return (
    <Secrets
      secretValue={secretValue}
      secretUrl={secretUrl}
      clearSecretValues={clearSecretValues}
      onSubmit={onSubmit}
      disableCreateSubmitButton={disableCreateSubmitButton}
    />
  );
}

export default SecretsContainer;
