import { createSelector } from "reselect";

import { denormalize } from "normalizr";

import { format } from "date-fns";

import {
  contract as contractSchema,
  timeRegistration as timeRegistrationSchema,
  projectParticipant as projectParticipantSchema,
} from "../schemas/management";

export const selectEntities = (state) => state.entities;

export const selectTimeRegistrationKeys = (state) => {
  if (!state.entities.timeRegistrations) return null;

  return Object.keys(state.entities.timeRegistrations);
};

export const selectProjectParticipantKeys = (state) => {
  if (!state.entities.projectParticipants) return null;

  return Object.keys(state.entities.projectParticipants);
};

export const selectContractKeys = (state) => {
  if (!state.entities.contracts) return null;

  return Object.keys(state.entities.contracts);
};

export const selectAllTimeRegistrations = createSelector(
  selectEntities,
  selectTimeRegistrationKeys,
  (entities, timeRegistrationKeys) => {
    return denormalize(
      timeRegistrationKeys,
      [timeRegistrationSchema],
      entities
    );
  }
);

export const selectTimeRegistrationsByDateRange = (startDate, endDate) =>
  createSelector(selectAllTimeRegistrations, (timeRegistrations) => {
    if (!timeRegistrations) return null;

    const startDateStr = format(startDate, "yyyy-MM-dd");
    const endDateStr = format(endDate, "yyyy-MM-dd");
    return timeRegistrations.filter((timeRegistration) => {
      return (
        timeRegistration.date >= startDateStr &&
        timeRegistration.date <= endDateStr
      );
    });
  });

export const selectAllProjectParticipants = createSelector(
  selectEntities,
  selectProjectParticipantKeys,
  (entities, projectParticipantKeys) => {
    return denormalize(
      projectParticipantKeys,
      [projectParticipantSchema],
      entities
    );
  }
);

export const selectAllContracts = createSelector(
  selectEntities,
  selectContractKeys,
  (entities, contractKeys) => {
    return denormalize(contractKeys, [contractSchema], entities);
  }
);
