import produce from "immer";

import { format } from "date-fns";

export const getParticipantOptions = (projectParticipants) => {
  if (!projectParticipants) return [];

  return projectParticipants.map((projectParticipant) => ({
    value: projectParticipant.id,
    label: `${projectParticipant.project.name} (${projectParticipant.project.client.name})`,
    dateStart: projectParticipant.date_start,
    dateEnd: projectParticipant.date_end,
  }));
};

export const getRoleOptions = (participantId, projectParticipants) => {
  if (!participantId || !projectParticipants) return [];

  const selectedParticipant = projectParticipants.find(
    (participant) => participant.id === participantId
  );
  if (!selectedParticipant) return [];

  return selectedParticipant.roles.map((role) => ({
    value: role.id,
    label: role.name,
  }));
};

export const aggregateMinutesFromTimeRegistrations = (timeRegistrations) => {
  if (!timeRegistrations?.reduce) return 0;

  return timeRegistrations.reduce(
    (accumulator, timeRegistration) =>
      accumulator + Number(timeRegistration.minutes),
    0
  );
};

export const getTimeRegistrationsForDay = (day, timeRegistrations) => {
  if (!timeRegistrations) return null;

  const dayDateStr = format(day, "yyyy-MM-dd");
  return timeRegistrations.filter(
    (timeRegistration) => timeRegistration.date === dayDateStr
  );
};

export const getTimeRegistrationsByDay = (days, timeRegistrations) =>
  produce({}, (draftObject) => {
    days.forEach((day) => {
      draftObject[day] = getTimeRegistrationsForDay(day, timeRegistrations);
    });
  });
