import { addDays, isSameDay, startOfMonth, endOfMonth } from "date-fns";

export function getFirstDayOfTheMonth() {
  return startOfMonth(new Date());
}

export function getLastDayOfTheMonth() {
  return endOfMonth(new Date());
}

export function getDaysAfterStartDate(startDate, amount) {
  const daysArray = [];

  for (let i = 1; i < amount; i++) {
    daysArray.push(addDays(startDate, i));
  }

  return daysArray;
}

// Get the index from the date array that has the same day as the first date argument
export function getIndexFromDateArray(date, days) {
  let index = null;

  days.forEach((day, currentIndex) => {
    if (isSameDay(date, day)) {
      index = currentIndex;
    }
  });

  return index;
}
