import React, { useState } from "react";

import { useSnackbar } from "notistack";

import { getRoleOptions, getParticipantOptions } from "../../utils/entities";
import { parseTimeRegistrationFormData } from "../../utils/data";

import TimeRegistrationFormDialog from "../../components/TimeRegistration/TimeRegistrationFormDialog";

export default ({
  projectParticipants,
  open,
  onCreateItem,
  onClose,
  ...props
}) => {
  const [selectedParticipantId, setSelectedParticipantId] = useState(null);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleAddFormDialogSubmit = async (data) => {
    setDisableSubmit(true);

    const parsedData = parseTimeRegistrationFormData(data, projectParticipants);

    try {
      await onCreateItem(parsedData);
      onClose();
      enqueueSnackbar("Successfully created time registration", {
        variant: "success",
      });
    } catch (e) {
      enqueueSnackbar("Failed to create time registration", {
        variant: "error",
      });
    }

    setDisableSubmit(false);
  };

  return (
    <TimeRegistrationFormDialog
      {...props}
      open={open}
      onSubmit={handleAddFormDialogSubmit}
      onClose={onClose}
      onParticipantSelect={(value) => setSelectedParticipantId(value)}
      projectOptions={getParticipantOptions(projectParticipants)}
      roleOptions={getRoleOptions(selectedParticipantId, projectParticipants)}
      disableSubmit={disableSubmit}
    />
  );
};
