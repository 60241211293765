import React, { useEffect, useState } from "react";

import { Paper, Tabs } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { getIndexFromDateArray } from "../../../utils/date";
import {
  aggregateMinutesFromTimeRegistrations,
  getTimeRegistrationsByDay,
} from "../../../utils/entities";

import TimeRegistrationTab from "../TimeRegistrationTab";
import TimeRegistrationTabPanel from "../TimeRegistrationTabPanel";
import TimeRegistrationControls from "../TimeRegistrationControls";

function a11yProps(index) {
  return {
    id: `time-registration-tab-${index}`,
    "aria-controls": `time-registration-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(5),
  },
  list: {
    overflow: "auto",

    [theme.breakpoints.up("sm")]: {
      maxHeight: "45rem",
    },
  },
  controlsWrapper: {
    marginBottom: theme.spacing(1),
  },
}));

const getTabIndex = (days) => getIndexFromDateArray(new Date(), days) || 0;

export default ({
  days,
  timeRegistrations,
  projectParticipants = [],
  controlsTitle,
  onLargePreviousClick = Function.prototype,
  onLargeNextClick = Function.prototype,
  onEditItem,
  onDeleteItem,
  onTabIndexChange = Function.prototype,
  onEmptyAddButtonClick,
}) => {
  const [tabIndex, setTabIndex] = useState(getTabIndex(days));
  const classes = useStyles();

  useEffect(() => {
    onTabIndexChange(tabIndex);
  }, [tabIndex, onTabIndexChange]);

  const handleSmallPreviousClick = () => {
    if (tabIndex === 0) {
      onLargePreviousClick();
      setTabIndex(days.length - 1);
    } else {
      setTabIndex((current) => current - 1);
    }
  };

  const handleSmallNextClick = () => {
    if (tabIndex === days.length - 1) {
      onLargeNextClick();
      setTabIndex(0);
    } else {
      setTabIndex((current) => current + 1);
    }
  };

  const timeRegistrationsByDay = getTimeRegistrationsByDay(
    days,
    timeRegistrations
  );

  return (
    <div className={classes.root}>
      <div className={classes.controlsWrapper}>
        <TimeRegistrationControls
          title={controlsTitle}
          onSmallPreviousClick={handleSmallPreviousClick}
          onSmallNextClick={handleSmallNextClick}
          onLargePreviousClick={onLargePreviousClick}
          onLargeNextClick={onLargeNextClick}
        />
      </div>
      <Paper>
        <Tabs
          value={tabIndex}
          onChange={(e, newIndex) => setTabIndex(newIndex)}
          variant="scrollable"
          aria-label="Days of the week"
        >
          {days.map((tabDate, index) => (
            <TimeRegistrationTab
              key={index}
              tabDate={tabDate}
              minutes={aggregateMinutesFromTimeRegistrations(
                timeRegistrationsByDay[tabDate]
              )}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </Paper>
      {days.map((day, index) => (
        <TimeRegistrationTabPanel
          id={`time-registration-tabpanel-${index}`}
          role="tabpanel"
          key={index}
          aria-labelledby={`time-registration-tab-${index}`}
          className={classes.list}
          timeRegistrations={timeRegistrationsByDay[day]}
          hidden={tabIndex !== index}
          onEditItem={onEditItem}
          onDeleteItem={onDeleteItem}
          projectParticipants={projectParticipants}
          onEmptyAddButtonClick={onEmptyAddButtonClick}
        />
      ))}
    </div>
  );
};
