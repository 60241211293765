import { addMinutes, differenceInMinutes } from "date-fns";

import { mod } from "./math";

export const getHoursFromHourString = (value) => {
  return value.substr(0, value.indexOf(":"));
};

export const getMinutesFromHourString = (value) => {
  return value.substr(value.indexOf(":") + 1);
};

export const isValidHourStringFormat = (value) => {
  if (typeof value !== "string") return false;

  const hoursString = getHoursFromHourString(value);
  const minutesString = getMinutesFromHourString(value);

  const hours = Number(hoursString || NaN);
  const minutes = Number(minutesString || NaN);

  return (
    minutesString.length === 2 &&
    !Number.isNaN(hours) &&
    !Number.isNaN(minutes) &&
    minutes < 60 &&
    hours >= 0
  );
};

export const isValidHourFormat = (value) => {
  return (
    (!Number.isNaN(Number(value)) &&
      Number(value) > 0 &&
      Number(value) <= 24) ||
    isValidHourStringFormat(value)
  );
};

export function convertMinutesToHourString(value) {
  const blankDate = new Date(0, 0);
  const targetDate = addMinutes(blankDate, value);

  const diff = differenceInMinutes(targetDate, blankDate);

  const hours = Math.floor(diff / 60)
    .toString()
    .padStart(2, "0");
  const minutes = mod(diff, 60).toString().padStart(2, "0");

  return `${hours}:${minutes}`;
}

export function convertHourStringToMinutes(value) {
  if (!isValidHourStringFormat(value)) return null;

  const hours = Number(getHoursFromHourString(value));
  const minutes = Number(getMinutesFromHourString(value));

  const blankDate = new Date(0, 0);
  const targetDate = addMinutes(blankDate, hours * 60 + minutes);

  const diff = differenceInMinutes(targetDate, blankDate);

  return diff;
}

export function convertDecimalToHourString(value) {
  return convertMinutesToHourString(value * 60);
}

export function convertHourStringToDecimal(value) {
  return convertHourStringToMinutes(value) / 60;
}

export const getHoursDisplayValue = (value) => {
  return Number.isNaN(Number(value))
    ? value.padStart(5, "0")
    : convertDecimalToHourString(value);
};
