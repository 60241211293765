import React from "react";

import { useController } from "react-hook-form";

import { KeyboardDatePicker } from "@material-ui/pickers";

import { format } from "date-fns";

const DATE_FORMAT = "yyyy-MM-dd";

function HookFormKeyboardDatePicker({
  name,
  control,
  defaultDate = new Date(),
  defaultValue,
  ...props
}) {
  const {
    field: { name: fieldName, onBlur, onChange, value, ref },
  } = useController({
    name,
    control,
    defaultValue: format(defaultDate, DATE_FORMAT),
    shouldUnregister: true,
  });

  return (
    <KeyboardDatePicker
      name={fieldName}
      onBlur={onBlur}
      onChange={onChange}
      inputRef={ref}
      value={value || null}
      format={DATE_FORMAT}
      {...props}
    />
  );
}

export default HookFormKeyboardDatePicker;
