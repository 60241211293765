import React, { useEffect, useState } from "react";

import { connect } from "react-redux";

import { format, startOfWeek as getStartOfWeek } from "date-fns";

import { getDaysAfterStartDate } from "../../utils/date";

import Dashboard from "./Dashboard";

import {
  getTimeRegistrationList,
  getProjectParticipantList,
} from "../../store/actions/management";
import { selectTimeRegistrationsByDateRange } from "../../store/selectors/management";
import { userDisplaySelector } from "../../store/selectors/user";

const DATE_FORMAT = "yyyy-MM-dd";

function DashboardContainer({
  userDisplay,
  getTimeRegistrations,
  loadProjectParticipants,
  loadTimeRegistrations,
}) {
  const startOfWeek = getStartOfWeek(new Date(), { weekStartsOn: 1 });
  const [timeRegistrationDays] = useState([
    startOfWeek,
    ...getDaysAfterStartDate(startOfWeek, 7),
  ]);

  useEffect(() => {
    loadProjectParticipants();
  }, [loadProjectParticipants]);

  useEffect(() => {
    const startDate = timeRegistrationDays[0];
    const endDate = timeRegistrationDays[timeRegistrationDays.length - 1];
    loadTimeRegistrations(startDate, endDate);
  }, [loadTimeRegistrations, timeRegistrationDays]);

  const timeRegistrations = getTimeRegistrations(
    timeRegistrationDays[0],
    timeRegistrationDays[timeRegistrationDays.length - 1]
  );

  let timeRegistrationsByProject = {}
  
  if (timeRegistrations) {
    timeRegistrations.forEach((obj) => {
      const projectName = obj.participant.project.name;
      if (!timeRegistrationsByProject[projectName]) {
        timeRegistrationsByProject[projectName] = 0;
      }
      timeRegistrationsByProject[projectName] += obj.minutes;
    })
  }
  
  timeRegistrationsByProject = Object.entries(timeRegistrationsByProject)

  return (
    <Dashboard
      userDisplay={userDisplay}
      timeRegistrations={timeRegistrations}
      timeRegistrationsByProject={timeRegistrationsByProject}
      timeRegistrationDays={timeRegistrationDays}
    />
  );
}

export default connect(
  (state) => {
    return {
      userDisplay: userDisplaySelector(state),
      getTimeRegistrations: (startDate, endDate) =>
        selectTimeRegistrationsByDateRange(startDate, endDate)(state),
    };
  },
  (dispatch) => ({
    loadProjectParticipants: () => dispatch(getProjectParticipantList()),
    loadTimeRegistrations: (startDate, endDate) => {
      const startDateStr = format(startDate, DATE_FORMAT);
      const endDateStr = format(endDate, DATE_FORMAT);
      return dispatch(getTimeRegistrationList(startDateStr, endDateStr));
    },
  })
)(DashboardContainer);
