import React from "react";

import { Link } from "react-router-dom";

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PrintIcon from "@material-ui/icons/Print";
import AssignmentIcon from "@material-ui/icons/Assignment";
import DashboardIcon from "@material-ui/icons/Dashboard";
import LockIcon from "@material-ui/icons/Lock";
import TimerIcon from "@material-ui/icons/Timer";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  list: {
    width: 250,
    flexGrow: 1,
  },
  version: {
    padding: theme.spacing(1),
  },
}));

export default ({ handleCloseMenu, ...listProps }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <List className={classes.list} role="presentation" {...listProps}>
        <ListItem button component={Link} to="/">
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
        <ListItem button component={Link} to="/contracts/">
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>
          <ListItemText primary="Contracts" />
        </ListItem>
        <ListItem button component={Link} to="/time-registration/">
          <ListItemIcon>
            <TimerIcon />
          </ListItemIcon>
          <ListItemText primary="Time registration" />
        </ListItem>
        <ListItem button component={Link} to="/reports/">
          <ListItemIcon>
            <PrintIcon />
          </ListItemIcon>
          <ListItemText primary="Reports" />
        </ListItem>
        <ListItem button component={Link} to="/secrets/">
          <ListItemIcon>
            <LockIcon />
          </ListItemIcon>
          <ListItemText primary="Secrets" />
        </ListItem>
      </List>
      <div className={classes.version}>
        <Typography variant="subtitle2">My Vicktor</Typography>
        <Typography variant="caption">
          {process.env.REACT_APP_GIT_SHA || "Development version"}
        </Typography>
      </div>
    </div>
  );
};
