import localForage from "localforage";

import { applyMiddleware, combineReducers, createStore } from "redux";
import promiseMiddleware from "redux-promise";
import { createLogger } from "redux-logger";
import { persistStore, persistReducer } from "redux-persist";

import entityReducer from "./reducers/entity";
import userReducer from "./reducers/user";

function configureStore() {
  const middleware = [promiseMiddleware];

  if (process.env.NODE_ENV === "development") {
    middleware.unshift(
      createLogger({
        collapsed: true,
        predicate: (getState, action) =>
          !action.type.startsWith("@") && !(action.payload instanceof Promise),
      })
    );
  }

  const persistConfig = {
    key: "vicktor",
    storage: localForage,
    whitelist: ["user"],
  };

  const rootReducer = combineReducers({
    user: userReducer,
    entities: entityReducer,
  });

  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const store = createStore(
    persistedReducer,
    {},
    applyMiddleware(...middleware)
  );

  const persistor = persistStore(store);

  return { store, persistor };
}

export default configureStore;
