import axios from "axios";

import { normalize } from "normalizr";

import {
  contract as contractSchema,
  timeRegistration as timeRegistrationSchema,
  projectParticipant as projectParticipantSchema,
} from "../store/schemas/management";

export const getTimeRegistrationList = (params = {}) => {
  return axios
    .get("/v1/management/time-registration-entries/", { params: params })
    .then((res) => normalize(res.data, [timeRegistrationSchema]));
};

export const createTimeRegistration = (data) => {
  return axios
    .post("/v1/management/time-registration-entries/", data)
    .then((res) => normalize(res.data, timeRegistrationSchema));
};

export const updateTimeRegistration = (id, data) => {
  return axios
    .put(`/v1/management/time-registration-entries/${id}/`, data)
    .then((res) => normalize(res.data, timeRegistrationSchema));
};

export const deleteTimeRegistration = (id) => {
  return axios
    .delete(`/v1/management/time-registration-entries/${id}/`)
    .then((res) => res.data);
};

export const getProjectParticipantList = () => {
  return axios
    .get("/v1/management/project-participants/")
    .then((res) => normalize(res.data, [projectParticipantSchema]));
};

export const getContractList = () => {
  return axios
    .get("/v1/management/contracts/")
    .then((res) => normalize(res.data, [contractSchema]));
};

export const getReport = (startDate, endDate) => {
  return axios.get(`/v1/management/report/`, {
    params: {
      start_date: startDate,
      end_date: endDate,
    },
  });
};
