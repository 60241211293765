import merge from "lodash/merge";

import produce from "immer";

const INITIAL_STATE = {};

const DELETE_ACTION_TYPE_ENTITY_KEY_MAP = {
  "timeregistration/delete": "timeRegistrations",
};

const reducer = (state = INITIAL_STATE, action) => {
  if (action.meta?.delete && !action.error) {
    const entityKey = DELETE_ACTION_TYPE_ENTITY_KEY_MAP[action.type];

    if (!entityKey || !state[entityKey]) return state;

    return produce(state, (draftState) => {
      delete draftState[entityKey][action.meta.id];
    });
  }

  if (action.payload && action.payload.entities) {
    return merge({}, state, action.payload.entities);
  }

  return state;
};

export default reducer;
