import React, { useState } from "react";

import {
  Box,
  Divider,
  Grid,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import CommentIcon from "@material-ui/icons/Comment";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import { useSnackbar } from "notistack";

import { getParticipantOptions, getRoleOptions } from "../../../utils/entities";
import { convertMinutesToHourString } from "../../../utils/text";

import TimeRegistrationFormDialog from "../TimeRegistrationFormDialog";
import ConfirmDialog from "../../ConfirmDialog";

const useStyles = makeStyles({
  secondaryAction: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
});

export default ({
  timeRegistration,
  projectParticipants,
  onEdit,
  onDelete,
}) => {
  const classes = useStyles();
  const [actionMenuAnchorEl, setActionMenuAnchorEl] = useState(null);
  const [showEditFormDialog, setShowEditFormDialog] = useState(false);
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const projectOptions = getParticipantOptions(projectParticipants);
  const roleOptions = getRoleOptions(
    timeRegistration.participant.id,
    projectParticipants
  );

  const handleDelete = async () => {
    try {
      await onDelete(timeRegistration.id);
      enqueueSnackbar("Successfully deleted time registration", {
        variant: "success",
      });
    } catch {
      enqueueSnackbar("Failed to delete time registration", {
        variant: "error",
      });
    }
  };

  const handleDeleteButtonClick = () => {
    setActionMenuAnchorEl(null);
    setShowDeleteConfirmDialog(true);
  };

  const handleEditButtonClick = () => {
    setActionMenuAnchorEl(null);
    setShowEditFormDialog(true);
  };

  const handleDialogSubmit = async (data) => {
    try {
      await onEdit(data);
      setShowEditFormDialog(false);
      enqueueSnackbar("Successfully edited time registration", {
        variant: "success",
      });
    } catch {
      enqueueSnackbar("Failed to edit time registration", {
        variant: "error",
      });
    }
  };

  return (
    <>
      <ListItem disabled={timeRegistration.booked}>
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="subtitle2">
              {timeRegistration.participant.project.client.name}
            </Typography>
            <Typography>{timeRegistration.participant.project.name}</Typography>
            <Typography variant="subtitle2">
              {timeRegistration.role.name}
            </Typography>
          </Grid>
        </Grid>
        <ListItemSecondaryAction className={classes.secondaryAction}>
          <Typography>
            {convertMinutesToHourString(timeRegistration.minutes)}
          </Typography>
          {timeRegistration.note && (
            <Box ml={1}>
              <CommentIcon fontSize="small" />
            </Box>
          )}
          {!timeRegistration.booked && (
            <>
              <IconButton
                aria-haspopup="true"
                aria-controls={`time-registration-action-menu-${timeRegistration.id}`}
                onClick={(event) => setActionMenuAnchorEl(event.currentTarget)}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id={`time-registration-action-menu-${timeRegistration.id}`}
                anchorEl={actionMenuAnchorEl}
                open={Boolean(actionMenuAnchorEl)}
                onClose={() => setActionMenuAnchorEl(null)}
                keepMounted
              >
                <MenuItem onClick={handleEditButtonClick}>
                  <ListItemIcon>
                    <EditIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="inherit">Edit</Typography>
                </MenuItem>
                <MenuItem onClick={handleDeleteButtonClick}>
                  <ListItemIcon>
                    <DeleteIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="inherit">Delete</Typography>
                </MenuItem>
              </Menu>
            </>
          )}
        </ListItemSecondaryAction>
      </ListItem>
      <Divider />
      <TimeRegistrationFormDialog
        open={showEditFormDialog}
        title="Edit time registration"
        submitButtonText="Edit"
        onSubmit={handleDialogSubmit}
        onClose={() => setShowEditFormDialog(false)}
        defaultValues={{
          participant: timeRegistration.participant.id,
          role: timeRegistration.role.id,
          hours: convertMinutesToHourString(timeRegistration.minutes),
          date: timeRegistration.date,
          note: timeRegistration.note,
        }}
        projectOptions={projectOptions}
        roleOptions={roleOptions}
      />
      <ConfirmDialog
        open={showDeleteConfirmDialog}
        title="Are you sure you want to delete this time registration?"
        description="By deleting this time registration, you won't be able to get it back again."
        onClose={() => setShowDeleteConfirmDialog(false)}
        onConfirm={handleDelete}
      />
    </>
  );
};
