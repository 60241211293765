import { handleActions } from "redux-actions";

import * as actions from "../actions/user";

const INITIAL_STATE = {
  email: null,
  firstName: null,
  lastName: null,
  accessToken: null,
};

const reducer = handleActions(
  {
    [actions.getUserDetails]: (state, action) => {
      if (!action.error) {
        const { data } = action.payload;

        return {
          ...state,
          email: data.email,
          firstName: data.first_name,
          lastName: data.last_name,
        };
      }

      // If we can't fetch user details, make sure the user is forced to logout
      return { ...INITIAL_STATE };
    },
    [actions.login]: (state, action) => {
      if (!action.error) {
        const { data } = action.payload;

        return {
          ...state,
          email: data.email,
          firstName: data.first_name,
          lastName: data.last_name,
          accessToken: data.access_token,
        };
      }

      return state;
    },
    [actions.logout]: (state, action) => {
      if (!action.error) {
        return { ...INITIAL_STATE };
      }

      return state;
    },
  },
  {
    ...INITIAL_STATE,
  }
);

export default reducer;
