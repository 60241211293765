import { schema } from "normalizr";

export const role = new schema.Entity("roles");

export const client = new schema.Entity("clients");

export const project = new schema.Entity("projects", {
  client: client,
});

export const projectParticipant = new schema.Entity("projectParticipants", {
  project: project,
  roles: [role],
});

export const timeRegistration = new schema.Entity("timeRegistrations", {
  role: role,
  participant: projectParticipant,
});

export const contract = new schema.Entity("contracts", {
  client: client,
  project: project,
});
