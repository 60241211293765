import axios from "axios";
import React, { useState } from "react";

import { format } from "date-fns";
import {
  Button,
  Grid,
  Paper,
  Typography,
  List,
  ListItem,
  Divider,
  ListItemSecondaryAction,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import { useSnackbar } from "notistack";

import ReportForm from "../../containers/ReportForm/ReportForm";
import MainLayout from "../../layouts/MainLayout";
import { convertMinutesToHourString } from "../../utils/text";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(5),
  },
  title: {
    marginBottom: theme.spacing(10),
  },
  form: {
    padding: theme.spacing(3),
  },
  button: {
    marginTop: theme.spacing(2),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  secondaryAction: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
}));

export default ({ userDisplay }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [result, setResult] = useState(null);
  const [downloading, setDownloading] = useState(false);

  function handleGoBack() {
    setResult(null);
  }

  async function handleDownload() {
    setDownloading(true);

    try {
      // Download ajax request as a file
      const res = await axios.get(`/v1/management/report/`, {
        responseType: "blob",
        params: {
          type: "csv",
        },
      });

      const url = URL.createObjectURL(res.data);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "vicktor_report.csv");
      document.body.append(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Failed to download report", {
        variant: "error",
      });
    }

    setDownloading(false);
  }

  if (result) {
    const entries = result.data;

    return (
      <MainLayout title="Reports">
        <Grid container justify="center" className={classes.root}>
          <Grid item xs={12} md={6}>
            <Paper className={classes.form}>
              {entries.length ? (
                <>
                  <List>
                    {entries.map((entry) => (
                      <ListItem key={entry.id}>
                        <Grid container>
                          <Grid item>
                            <Typography variant="subtitle2">
                              {entry.participant.project.name} (
                              {entry.participant.project.client.name})
                            </Typography>
                            <Grid container direction="row" alignItems="center">
                              <CalendarTodayIcon className={classes.icon} />{" "}
                              {entry.date}
                            </Grid>
                          </Grid>
                        </Grid>
                        <ListItemSecondaryAction
                          className={classes.secondaryAction}
                        >
                          <Typography>
                            {convertMinutesToHourString(entry.minutes)}
                          </Typography>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                  <Divider />
                  <Button
                    className={classes.button}
                    fullWidth
                    variant="outlined"
                    onClick={handleDownload}
                    disabled={downloading}
                  >
                    {downloading ? "Downloading..." : "Download report"}
                  </Button>
                </>
              ) : (
                <>
                  <Typography variant="body1">
                    No time registrations found
                  </Typography>
                  <Divider />
                </>
              )}
              <Button
                className={classes.button}
                fullWidth
                type="submit"
                color="primary"
                variant="contained"
                onClick={handleGoBack}
              >
                Create another report
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </MainLayout>
    );
  }

  return (
    <MainLayout title="Reports">
      <Grid container justify="center" className={classes.root}>
        <Grid item xs={12} md={6}>
          <Paper className={classes.form}>
            <ReportForm onResult={setResult} />
          </Paper>
        </Grid>
      </Grid>
    </MainLayout>
  );
};
