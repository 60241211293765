import React, { useEffect, useState } from "react";

import { IconButton, TextField, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FileCopyIcon from "@material-ui/icons/FileCopy";

import { useSnackbar } from "notistack";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  iconButton: {
    marginLeft: "auto",
  },
}));

export default ({ content, inputId = "" }) => {
  const [openCopyCompletedTooltip, setOpenCopyCompletedTooltip] = useState(
    false
  );
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const copyValueToClipboard = (value) => {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        setOpenCopyCompletedTooltip(true);
      })
      .catch(() => {
        enqueueSnackbar("Failed to copy value to clipboard", {
          variant: "error",
        });
      });
  };

  useEffect(() => {
    if (!openCopyCompletedTooltip) return;

    const clearTooltipTimeout = setTimeout(() => {
      setOpenCopyCompletedTooltip(false);
    }, 1250);

    return () => clearTimeout(clearTooltipTimeout);
  }, [openCopyCompletedTooltip, setOpenCopyCompletedTooltip]);

  return (
    <div className={classes.root}>
      <TextField
        fullWidth
        id={inputId}
        value={content}
        onFocus={(event) => event.target.select()}
        InputProps={{
          readOnly: true,
        }}
      />
      <Tooltip arrow title="Copied!" open={openCopyCompletedTooltip}>
        <IconButton
          className={classes.iconButton}
          onClick={() => copyValueToClipboard(content)}
        >
          <FileCopyIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};
